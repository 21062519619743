<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "BaseFormDropdown",
  components: { Icon },
  props: {
    icon: { type: String, required: false, default: "bx-caret-down" },
    value: { type: String, required: false, default: null },
    customStyle: { type: Object, required: false },
  },
  emits: ["on-shown", "on-hidden"],
};
</script>

<template>
  <b-dropdown
    :style="customStyle"
    class="dropdownButton"
    no-caret
    right
    variant="none"
    @hidden="$emit('on-hidden')"
    @shown="$emit('on-shown')"
  >
    <template #button-content>
      <span v-if="value" class="dropdownButton-text">{{ value }}</span>
      <Icon :icon="icon" />
    </template>

    <slot></slot>
  </b-dropdown>
</template>

<style lang="scss" scoped>
@import "@/style/fonts";
@import "@/style/colors";

.dropdownButton {
  height: 60px;
  min-height: 60px;
  width: 120px;
  border-radius: 12px;
  border: 1px solid $secondary-white-grey;
  transition: border-color 0.2s;

  &:focus,
  &:focus-within,
  &:active {
    border: 1px solid $brand-color;
  }

  &:hover {
    * {
      color: $secondary-grey;
    }
  }

  &-text {
    @include raleway-regular;
    @include fontSize-big;
    color: $primary-grey;
    margin-right: 5px;
  }
}

.dropdownButton:deep(button) {
  color: $primary-grey;
  padding: 0;
  margin: 0;
  border: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    border-color: transparent;
  }
}

.dropdownButton:deep(ul) {
  border-radius: 16px;
  border: 1px solid rgba(1, 187, 236, 0.05);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}

.dropdownButton:deep(a) {
  @include raleway-regular;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  gap: 10px;
  padding: 12px 24px;
  font-size: 14px;

  & svg {
    color: #bec2cd;
  }

  &:active {
    background-color: $primary-background;
    outline: none;
  }

  &:focus-visible {
    background-color: $primary-background;
    outline: none;
  }

  &:hover {
    background-color: $primary-background;
    outline: none;
  }
}
</style>