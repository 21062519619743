<script>
import { Icon } from "@iconify/vue2";

import BaseNotification from "@/components/ui/BaseNotification.vue";
import MobileMenu from "@/components/menu/MobileMenu.vue";
import BaseLogo from "@/components/ui/BaseLogo.vue";

export default {
  name: "MobileHeader",
  components: {
    Icon,
    BaseNotification,
    MobileMenu,
    BaseLogo,
  },
  data() {
    return {
      mobileMenuModalId: "mobileMenuModal",
    };
  },
};
</script>

<template>
  <div class="header">
    <div class="logo">
      <router-link to="/campaigns" class="">
        <BaseLogo />
      </router-link>
    </div>

    <div class="controls">
      <BaseNotification :size="50" />

      <button class="hamburger" @click="$bvModal.show(mobileMenuModalId)">
        <img src="@/assets/svgs/hamburger.svg" alt="hamburger" />
      </button>
    </div>

    <MobileMenu :modalId="mobileMenuModalId" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";

.header {
  display: none;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    position: sticky;
    top: 0;
    z-index: 4;
    background-color: $primary-background;
  }

  & .logo {
    &-image {
      width: 100px;
    }
  }

  & .controls {
    display: flex;
    align-items: center;
    column-gap: 15px;

    & .hamburger {
      padding: 0px;
      border: none;
      overflow: hidden;
    }
  }
}
</style>
