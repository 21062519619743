<script>
import BaseEmptyContainer from "@/components/ui/BaseEmptyContainer.vue";

export default {
  name: "BaseEmptyErrorContainer",
  components: {
    BaseEmptyContainer,
  },
  methods: {
    redirectToCampaigns() {
      this.$router.push({
        name: "campaigns",
      });
    },
  },
};
</script>

<template>
  <BaseEmptyContainer
    :title="$gettext('Oops! Something went wrong')"
    :description="
      $gettext('An unexpected error has occurred. Please try again later.')
    "
    :buttonText="$gettext('Перейти к кампаниям')"
    @on-click="redirectToCampaigns"
  />
</template>

<style>
</style>