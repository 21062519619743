import ApiService from "@/api/ApiService";
import { isNull } from "@/functions/utils";

const api = new ApiService();

const state = {
  topicsOptions: [],
  locationOptions: [],
  languageOptions: [],
  brandOptions: [],
  interestOptions: [],
};

const mutations = {
  updateTopicsOptions(state, options) {
    state.topicsOptions = options;
  },
  updateLocationOptions(state, options) {
    state.locationOptions = options;
  },
  updateLanguageOptions(state, options) {
    state.languageOptions = options;
  },
  updateBrandOptions(state, options) {
    state.brandOptions = options;
  },
  updateInterestOptions(state, options) {
    state.interestOptions = options;
  },
};

const actions = {
  getTopics(context, { network, value }) {
    const params = new URLSearchParams();
    params.set("network", network);
    params.set("search", value);

    return api.get(`/api/social/topics/?${params}`).then(res => {
      context.commit("updateTopicsOptions", res.data);
    });
  },
  getLocations(context, { network, value }) {
    return api.get(`/api/social/locations/?network=${network}&search=${value}`).then(res => {
      context.commit("updateLocationOptions", res.data);
    });
  },
  getLanguages(context, { network, value }) {
    return api.get(`/api/social/languages/?network=${network}&search=${value}`).then(res => {
      context.commit("updateLanguageOptions", res.data);
    });
  },
  getBrands(context, { network, value }) {
    return api.get(`/api/social/brands/?network=${network}&search=${value}`).then(res => {
      context.commit("updateBrandOptions", res.data);
    });
  },
  getInterests(context, { network, value }) {
    const query = new URLSearchParams();
    network && query.set("network", network);
    !isNull(value) && query.set("search", value);

    return api.get(`/api/social/interests/?${query}`).then(res => {
      context.commit("updateInterestOptions", res.data);
    });
  },
  getInfluencerProfiles(context, params) {
    // params type: { search: Array, page: String, network: String }
    return api.post("/api/social/search/", params);
  },
  searchInfluencerProfilesByUsername(context, { username, network }) {
    // params type: { search: Array, page: String, network: String }
    return api.post("/api/social/profile/", { username, network });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
