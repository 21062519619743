<script>
import { Icon } from '@iconify/vue2';
import { mapGetters } from 'vuex';

import colors from '@/style/colors';
import { RouteName } from '@/values/routeName';
import { UserRole } from '@/values/user';

export default {
  components: {
    Icon,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['on-click'],
  data() {
    return {
      items: [],
      colors: colors,
    };
  },
  computed: {
    ...mapGetters({ currentUserRole: 'currentUserRole' }),
    filteredItems() {
      return this.items.filter((i) => i.roles.includes(this.currentUserRole));
    },
  },
  mounted() {
    this.items = [
      {
        title: this.$gettext('Campaigns'),
        icon: 'ci-trending-up',
        done: false,
        route: 'campaigns',
        subdomains: ['campaigns', 'campaign'],
        roles: [UserRole.User, UserRole.Admin],
      },
      {
        title: this.$gettext('Favourites'),
        icon: 'ci-star',
        done: false,
        route: 'favourites',
        subdomains: ['favourites'],
        roles: [UserRole.User, UserRole.Admin],
      },
      {
        title: this.$gettext('Settings'),
        icon: 'ci-settings-future',
        done: false,
        route: 'settings',
        subdomains: ['settings'],
        roles: [UserRole.User, UserRole.Admin],
      },
      {
        title: this.$gettext('Information'),
        icon: 'ci-info',
        done: false,
        url: 'https://advy.ai',
        roles: [UserRole.User, UserRole.Admin],
      },
      // {
      //   title: this.$gettext('Agency dashboard'),
      //   icon: 'ci-layer',
      //   done: false,
      //   route: RouteName.AgencyCabinetCampaignList,
      //   subdomains: [RouteName.AgencyCabinetCampaignList, RouteName.AgencyCabinetCampaign],
      //   roles: [UserRole.Admin],
      // },
    ];
  },
  methods: {
    activeTab(subdomains) {
      const route = this.$route?.matched?.[0]?.name;
      return subdomains.includes(route);
    },
  },
};
</script>

<template>
  <ul class="nav">
    <li v-for="item in filteredItems" :key="item.icon">
      <router-link
        v-if="item.route"
        :class="{ 'active-tab': activeTab(item.subdomains) }"
        :to="{ name: item.route }"
        class="nav-item"
        @click.native="$emit('on-click')"
      >
        <Icon :icon="item.icon" class="icon" />
        <span v-if="!isCollapsed">{{ item.title }}</span>
        <Icon
          v-if="activeTab(item.subdomains) && !isCollapsed"
          :color="colors.primaryGrey"
          class="triangle-arrow-icon"
          icon="bxs-right-arrow"
        />
      </router-link>

      <a v-if="item.url" :href="item.url" class="nav-item" target="_blank">
        <Icon :icon="item.icon" class="icon" />
        <span v-if="!isCollapsed">{{ item.title }}</span>
      </a>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
  @import '@/style/fonts.scss';
  @import '@/style/colors.scss';
  @import '@/style/breakpoints.scss';

  .nav {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .nav-item {
    @include raleway-semibold;
    @include fontSize-normal;
    height: 44px;
    max-width: 90%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background-color: transparent;
    gap: 10px;
    border-radius: 16px;
    cursor: pointer;
    color: $primary-grey;
    white-space: nowrap;

    @include for-big-desktop-up {
      @include fontSize-big;
      height: 48px;
    }

    & svg {
      color: $primary-grey;
    }

    & span {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .active-tab {
    background-color: #ffffff;
    color: $primary-black;

    & svg {
      color: $primary-black;
    }
  }

  .icon {
    width: 20px;
    height: 20px;

    @include for-big-desktop-up {
      width: 24px;
      height: 24px;
    }
  }

  .triangle-arrow-icon {
    width: 8px;
    height: 8px;
  }
</style>
