<script>
import isEqual from 'lodash-es/isEqual';
import mitt from 'mitt';
import { createNamespacedHelpers } from 'vuex';

import { TableCellPlaceholder, TextCellField } from '@/components/ui/tables';
import { toNumber, toNumberString } from '@/functions/normalize';

import { ColumnKey } from '../campaignInfluencerTableColumn';
import { calcCommissionCost, calcTotalCost } from '../tableFormulas';
import { createTablePatchPayload } from '../tableHelpers';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');
const emitter = mitt();

export default {
  name: 'InfluencerPriceCell',
  components: { TableCellPlaceholder, TextCellField },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    ColumnKey,
    innerValue: '',
  }),
  computed: {
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    value() {
      return this.getValue(this.currentColumn, this.cell.value);
    },
    currency() {
      return this.row.originalValue.currency ?? '';
    },
    formattedValue() {
      switch (this.currentColumn) {
        case ColumnKey.Price:
        case ColumnKey.TotalPrice:
          return this.toNumberString(this.innerValue || 0, ` ${this.currency}`);
        case ColumnKey.Commission:
          return this.toNumberString(this.innerValue || 0, ' %');
        default:
          return toNumberString(this.innerValue || 0);
      }
    },
  },
  watch: {
    value: {
      handler(value) {
        this.innerValue = value ?? '';
      },
      immediate: true,
    },
  },
  created() {
    emitter.on('cell:update', this.onCrossCellsUpdate.bind(this));
  },
  beforeDestroy() {
    emitter.off('cell:update', this.onCrossCellsUpdate.bind(this));
  },
  methods: {
    toNumberString,
    ...agencyCabinetStore.mapActions({ updateRow: 'updateRow' }),
    getValue(key, state) {
      switch (key) {
        case ColumnKey.Commission:
          return toNumber(state.commission);
        case ColumnKey.Price:
          return toNumber(state.price);
        case ColumnKey.TotalPrice:
          return Math.ceil(
            toNumber(calcTotalCost(toNumber(state.price), toNumber(state.commission))),
          );
        default:
          return 0;
      }
    },
    onCrossCellsUpdate({ cellId, rowId, payload }) {
      if (cellId !== this.currentColumn || rowId !== this.row.id) return;
      this.innerValue = this.getValue(cellId, payload);
    },
    onRevert() {
      this.innerValue = this.value;
    },
    async onSubmit(e) {
      const rowId = this.row.id;
      const originalValue = this.row.originalValue;
      const inputValue = e.target.value.replace(/\s+/g, '');
      const prevValue = this.value;
      const nextValue = Number(inputValue);

      if (isEqual(prevValue, nextValue)) {
        return;
      }

      let payload = {};

      switch (this.currentColumn) {
        case ColumnKey.TotalPrice: {
          const cellId = ColumnKey.Commission;
          const commission = calcCommissionCost(originalValue.price, nextValue);

          payload = createTablePatchPayload(originalValue, { commission });
          emitter.emit('cell:update', { rowId, cellId, payload: { commission } });
          break;
        }
        case ColumnKey.Commission: {
          const cellId = ColumnKey.TotalPrice;
          const totalPrice = calcTotalCost(originalValue.price, nextValue);

          payload = createTablePatchPayload(originalValue, { [this.currentColumn]: nextValue });
          emitter.emit('cell:update', { rowId, cellId, payload: { totalPrice } });
          break;
        }
        case ColumnKey.Price: {
          const cellId = ColumnKey.TotalPrice;
          payload = createTablePatchPayload(originalValue, { [this.currentColumn]: nextValue });
          emitter.emit('cell:update', { rowId, cellId, payload: originalValue });
          break;
        }
        default: {
          payload = createTablePatchPayload(originalValue, { [this.currentColumn]: nextValue });
        }
      }

      this.innerValue = nextValue;
      const isSubmitted = await this.updateRow(payload);
      !isSubmitted && this.onRevert();
    },
  },
};
</script>

<template>
  <TextCellField
    ref="cell"
    :value="innerValue"
    class="price-cell"
    @revert="onRevert"
    @submit="onSubmit"
  >
    <div v-if="innerValue" class="price-cell__content">
      <span class="price-cell__value">
        {{ formattedValue }}
      </span>
    </div>
    <TableCellPlaceholder v-else />
  </TextCellField>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .price-cell {
    justify-content: end;

    &__content {
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    &__value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
