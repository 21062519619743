<script>
import ModalError from '@/components/menu/ModalError';
import { Loading } from '@/components/ui';
import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import BaseSolidButton from '@/components/ui/BaseSolidButton.vue';
import BaseTextRadioButton from '@/components/ui/BaseTextRadioButton.vue';
import { BaseFormInput } from '@/components/ui/form';
import { toNumberString } from '@/functions/normalize';
import { ComponentState } from '@/values/componentState';
import { Currency } from '@/values/payment';

import { calcCommissionCost, calcTotalCost } from '../tableFormulas';
import { createMultipleTablePatchPayload } from '../tableHelpers';
import MultipleEditingModalSection from './MultipleEditingModalSection.vue';

export default {
  name: 'MultipleEditingModal',
  components: {
    BaseSolidButton,
    BaseFormInput,
    BaseProvidedModal,
    BaseTextRadioButton,
    MultipleEditingModalSection,
    Loading,
    ModalError,
  },
  props: {
    modalId: { type: String, required: true },
    data: { type: Array, required: true },
  },
  emits: ['hide', 'update:data'],
  data: () => ({
    ComponentState,
    state: ComponentState.isLoading,
    errorModalId: 'errorModal',
    currency: null,
    commission: null,
    totalPriceValue: null,
  }),
  computed: {
    optedCurrency() {
      const currencySet = new Set(this.data.map((row) => row.currency));
      return currencySet.size > 1 ? 'USD' : Array.from(currencySet)[0];
    },
    config() {
      return {
        currencyOptions: {
          [Currency.USD]: this.$gettext('USD'),
          [Currency.EUR]: this.$gettext('EUR'),
          [Currency.RUB]: this.$gettext('RUB'),
          [Currency.BYN]: this.$gettext('BYN'),
          [Currency.KZT]: this.$gettext('KZT'),
        },
        commission: this.$gettext('New commission'),
        totalPrice: this.$gettext('New total price'),
      };
    },
    calculatedBloggerPrice() {
      return toNumberString(this.rawBloggerPrice, this.optedCurrency, true);
    },
    calculatedTotalPrice() {
      const sum =
        this.optedCurrency !== 'USD'
          ? this.data.reduce((sum, row) => sum + calcTotalCost(row.price, row.commission), 0)
          : this.data.reduce((sum, row) => sum + calcTotalCost(row.usd_price, row.commission), 0);

      return toNumberString(Math.ceil(sum), this.optedCurrency, true);
    },
    rawBloggerPrice() {
      return this.optedCurrency !== 'USD'
        ? this.data.reduce((sum, row) => sum + (row.price || 0), 0)
        : this.data.reduce((sum, row) => sum + (row.usd_price || 0), 0);
    },
  },
  created() {
    if (this.data) this.state = ComponentState.isSuccess;
  },
  methods: {
    handleRadioChange(id) {
      if (this.currency === id) {
        this.currency = null;
        return;
      }
      this.currency = id;
    },
    updateCommission(value) {
      if (!value || value > 100) {
        this.commission = null;
        this.totalPriceValue = null;
        return;
      }

      const bloggerPrice = this.rawBloggerPrice;
      this.commission = parseFloat(value) || 0;
      this.totalPriceValue = calcTotalCost(bloggerPrice, this.commission);
    },
    updateTotalPrice(value) {
      if (!value) {
        this.commission = null;
        this.totalPriceValue = null;
        return;
      }

      const totalPrice = parseFloat(value) || 0;
      const bloggerPrice = this.rawBloggerPrice;

      if (totalPrice < bloggerPrice) {
        this.totalPriceValue = totalPrice;
        return;
      }

      this.commission = bloggerPrice === 0 ? 0 : calcCommissionCost(bloggerPrice, totalPrice);
      this.totalPriceValue = totalPrice;
    },
    editData() {
      const changesOption = ['currency', 'commission'];
      const changesArr = changesOption
        .filter((option) => this[option] !== null)
        .map((option) => ({ [option]: this[option] }));

      if (!changesArr.length) return;

      const payload = createMultipleTablePatchPayload(this.data, changesArr);
      this.$emit('update:data', payload);
      this.resetData();
    },
    resetData() {
      this.currency = null;
      this.commission = null;
      this.totalPriceValue = null;
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="modalId"
    :hide-header="false"
    :title="$gettext('Edit')"
    size="lg"
    @hide="$emit('hide')"
  >
    <ModalError :modal-id="errorModalId" @cancel="$bvModal.hide(errorModalId)" />

    <div v-if="state === ComponentState.isLoading" class="m-editing-modal__loader">
      <Loading type="round" />
    </div>

    <div v-if="state === ComponentState.isSuccess" class="m-editing-modal__wrapper">
      <MultipleEditingModalSection :title="$gettext('Currency')">
        <div class="m-editing-modal__radio-group">
          <BaseTextRadioButton
            v-for="(label, id) in config.currencyOptions"
            :id="id"
            :key="id"
            :checked="currency === id"
            :label="label"
            name="option"
            @change="handleRadioChange(id)"
          />
        </div>
      </MultipleEditingModalSection>

      <MultipleEditingModalSection :title="$gettext('Commission')">
        <template #header-content>
          <div class="m-editing-modal__info">
            <div>
              <translate>Price for</translate>
              <span>&nbsp;{{ data.length }}&nbsp;</span>
              <translate>selected bloggers</translate>
              <b>:&nbsp;{{ calculatedBloggerPrice }}</b>
            </div>

            <div>
              <translate>Total price for</translate>
              <span>&nbsp;{{ data.length }}&nbsp;</span>
              <translate>selected bloggers</translate>
              <b>:&nbsp;{{ calculatedTotalPrice }}</b>
            </div>
          </div>
        </template>

        <div class="m-editing-modal__input-group">
          <BaseFormInput
            v-model="commission"
            :label="$gettext('New commission')"
            @input="updateCommission"
          />
          <BaseFormInput
            v-model="totalPriceValue"
            :label="$gettext('New total price')"
            @input="updateTotalPrice"
          />
        </div>
      </MultipleEditingModalSection>

      <footer class="m-editing-modal__footer">
        <BaseSolidButton
          :style="{ 'min-width': '280px' }"
          :text="$gettext('Save')"
          size="lg"
          variant="primary"
          @click="editData"
        />
      </footer>
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .m-editing-modal {
    &__loader {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      background-color: colors.$primary-background;
      opacity: 0.7;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-top: 10px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-title {
        @include fonts.raleway-bold;

        font-size: 28px;
        line-height: 36px;
        color: colors.$primary-black;
        margin: 0;

        @include breakpoints.for-phone-only {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    &__info {
      @include fonts.raleway-medium;
      @include fonts.fontSize-big;

      display: flex;
      gap: 4px;
      flex-direction: column;
      line-height: 24px;
      color: colors.$primary-black;
    }

    &__input-group {
      display: flex;
      gap: 15px;
    }

    &__radio-group {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__submit-btn {
      @include fonts.raleway-bold;
      @include fonts.fontSize-big;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      border-radius: 16px;
      color: colors.$primary-white;
      background-color: colors.$brand-color;
      width: 40%;
    }
  }
</style>
