<script>
import { Icon } from '@iconify/vue2';

import { modalService } from '@/services/modal.service';

export default {
  name: 'BaseProvidedModal',
  components: { Icon },
  props: {
    id: { type: String, required: true },
    title: { type: String, required: false, default: '' },
    size: { type: String, required: false, default: '' },
    closable: { type: Boolean, default: true },
    hideHeader: { type: Boolean, default: false },
    titleAlign: { validator: (v) => ['left', 'center'].includes(v), default: 'left' },
    full: { type: Boolean, default: false },
    contentStyles: { type: Object, required: false, default: () => ({}) },
    closeOutBackdrop: { type: Boolean, default: false },
  },
  emits: ['hide'],
  computed: {
    state() {
      return modalService().getState(this.id) ?? { isOpen: false, payload: {} };
    },
  },
  watch: {
    'state.isOpen'(value) {
      this.$nextTick(() => {
        value ? this.$bvModal.show(this.id) : this.$bvModal.hide(this.id);
      });
    },
  },
  created() {
    modalService().register(this.id);
  },
  beforeDestroy() {
    modalService().unregister(this.id);
  },
  methods: {
    open(payload = {}) {
      modalService().open(this.id, payload);
    },
    close() {
      modalService().close(this.id);
    },
    onHide(e) {
      if (e.trigger === 'backdrop' || e.trigger === 'esc') {
        this.onClose();
      }
    },
    onClose() {
      this.$emit('hide');
      this.close();
    },
  },
};
</script>

<template>
  <b-modal
    v-if="state.isOpen"
    :id="id"
    :content-class="`base-modal ${full ? 'base-modal--full' : ''}`"
    :hide-footer="true"
    :hide-header="hideHeader"
    :no-close-on-backdrop="closeOutBackdrop || !closable"
    :size="size"
    :title="title"
    body-class="base-modal__body"
    centered
    footer-class="base-modal__footer"
    header-class="base-modal__header"
    scrollable
    @close="onClose"
    @hide="onHide"
  >
    <template #modal-header>
      <slot :close="close" :open="open" name="header">
        <h3
          :class="{
            'base-modal__header-title--center': titleAlign === 'center',
            [`base-modal__header-title--${size}`]: true,
          }"
          class="base-modal__header-title"
        >
          {{ title }}
        </h3>
      </slot>
    </template>

    <button v-if="closable" class="base-modal__close-btn" @click="close">
      <Icon height="30" icon="bx:x" width="30" />
    </button>

    <div :style="contentStyles" class="base-modal__body-content">
      <slot :payload="state.payload" />
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  :deep(.modal > .modal-dialog) {
    @include breakpoints.for-phone-only {
      margin: 0;
      height: 100vh;
    }
  }

  :deep(.modal > .modal-dialog-scrollable) {
    @-moz-document url-prefix() {
      scrollbar-width: none;
      scrollbar-color: #e1d7ff #f6f6f6;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f6f6f6;
      margin: 15px 0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #e1d7ff;
      border-radius: 12px;
    }

    @include breakpoints.for-phone-only {
      max-height: 100%;
    }
  }
</style>

<style lang="scss">
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .base-modal {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 20px;
    padding: 40px 20px 40px 40px !important;
    border-radius: 16px;
    background-color: colors.$primary-background;

    @include breakpoints.for-phone-only {
      padding: 20px 10px 20px 20px !important;
      border-radius: 0;
      height: 100%;
    }

    &.base-modal--full {
      height: 86vh;

      @include breakpoints.for-phone-only {
        height: 100vh;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 16px;
      border: none;
      padding: 0 20px 0 0;

      &-title {
        @include fonts.raleway-bold;

        flex-grow: 1;
        font-size: 28px;
        line-height: 36px;
        color: colors.$primary-black;
        margin: 0;

        @include breakpoints.for-phone-only {
          font-size: 24px;
          line-height: 28px;
        }

        &.base-modal__header-title--center {
          text-align: center;
        }

        &.base-modal__header-title--md {
          font-size: 24px;
          line-height: 32px;
        }

        &.base-modal__header-title--lg {
          font-size: 28px;
          line-height: 36px;
        }
      }
    }

    &__close-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      color: colors.$secondary-light-grey;
      transition: all 0.3s;

      @include breakpoints.for-phone-only {
        right: 10px;
        top: 10px;
      }

      &:hover {
        color: colors.$secondary-grey;
      }

      &:active {
        color: colors.$primary-black;
      }
    }

    &__body {
      position: static;
      min-height: 100px;
      width: 100%;
      height: 100%;
      // To have margins on the left and right of the scrollbar
      padding: 0 20px 0 0;

      @include breakpoints.for-phone-only {
        padding: 0 10px 0 0;
      }

      &-content {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 0;
    }
  }

  .backgroundWhite {
    background-color: colors.$primary-white;
  }
</style>
