export function calcTotalCost(price, commission) {
  if (price <= 0 || commission < 0 || commission > 100) return 0;
  const total = 100 * (price / (100 - commission));
  return Number(total.toFixed(2));
}

export function calcCommissionCost(price, totalPrice) {
  if (price <= 0 || totalPrice <= 0 || price >= totalPrice) return 0;
  const commission = 100 * (1 - price / totalPrice);
  return commission.toFixed(2);
}

export function calcReachFromSubscribers(targetReach, followersCount) {
  if (targetReach === 0 || followersCount === 0) return;
  return ((targetReach / followersCount) * 100).toFixed(2);
}

export function calcReachFromRest(targetReach, reach_followers) {
  if (targetReach === 0 || !reach_followers) return;
  return targetReach === 0 ? 0 : ((reach_followers / targetReach) * 100).toFixed(2);
}

export function calcSuspiciousPercent(audience_types) {
  if (
    !audience_types ||
    !('suspicious' in audience_types) ||
    !('mass_followers' in audience_types)
  ) {
    return;
  }
  const { suspicious = 0, mass_followers = 0 } = audience_types;
  return (suspicious + mass_followers).toFixed(2);
}
